import { PROXY_TREE, VALUE } from 'proxy-state-tree'

import { IState } from '.'

type TStates = IState & {
  state: string | number,
}

export type StatemachineTransitions<States extends TStates> = {
  [State in States["state"]]: Array<States["state"]>
}

export type Statemachine<States extends TStates> = States & {
  reset: () => void
  whenTransitioned: (state: States["state"]) => Promise<void>
} & {
  [State in States["state"]]: <O>(entry?:  (current: Statemachine<States> & (States extends { state: State } ? States : never)) => O, exit?: (current: Statemachine<States> & (States extends { state: State } ? States : never)) => void) => O
}

const CURRENT_EXIT = Symbol('CURRENT_EXIT')
const INITIAL_STATE = Symbol('INITIAL_STATE')
const PENDING_TRANSITIONS = Symbol('PENDING_TRANSITIONS')

class StateMachine<States extends TStates>  {
  state: States["state"]
  private [CURRENT_EXIT]: (() => void) | undefined
  private [INITIAL_STATE]: States["state"]
  private [PENDING_TRANSITIONS]: { [key: string]: Function[] } = {}
  constructor(transitions: StatemachineTransitions<States>, definition: States) {
    this[INITIAL_STATE] = definition.state
    Object.assign(this, definition)

    Object.keys(transitions).reduce((aggr, key) => {
      aggr[key] = function (entry, exit) {
        if (transitions[this.state].includes(key as any)) {
          const tree = (this[PROXY_TREE].master.mutationTree || this[PROXY_TREE])

          tree.enableMutations()
          if (this[CURRENT_EXIT]) this[CURRENT_EXIT](this)
          this[VALUE][CURRENT_EXIT] = exit
          this.state = key as any
          const result = entry && entry(this)
          tree.blockMutations()

          if (this[VALUE][PENDING_TRANSITIONS][this.state]) {
            this[VALUE][PENDING_TRANSITIONS][this.state].forEach((resolve) => resolve())
            this[VALUE][PENDING_TRANSITIONS][this.state] = []
          }

          return result
        }
      }
  
      return aggr
    }, this)
  }
  reset() {
    const exit = this[VALUE][CURRENT_EXIT]
    if (typeof exit === 'function') {
      exit()
      this[VALUE][CURRENT_EXIT] = undefined
    }
    this.state = this[INITIAL_STATE]
  }
  whenTransitioned(state: States["state"]) {
    if (!this[VALUE][PENDING_TRANSITIONS][state]) {
      this[VALUE][PENDING_TRANSITIONS][state] = []
    }

    return new Promise((resolve) => {
      this[VALUE][PENDING_TRANSITIONS][state].push(resolve)
    })
  }
}

export function statemachine<States extends TStates>(definition: StatemachineTransitions<States>, state: States): Statemachine<States> {
  return new StateMachine(definition, state) as any
}
