import 'proxy-state-tree'

import {
  EventType,
  IConfiguration,
  MODE_SSR,
  Overmind,
  OvermindMock,
} from 'overmind'
import { IMutationCallback } from 'proxy-state-tree'
import * as react from 'react'

const IS_PRODUCTION = process.env.NODE_ENV === 'production'
const IS_TEST = process.env.NODE_ENV === 'test'
const isNode =
  !IS_TEST && process && process.title && process.title.includes('node')

export type IReactComponent<P = any> =
  | react.StatelessComponent<P>
  | react.ComponentClass<P>
  | react.ClassicComponentClass<P>

// Diff / Omit taken from https://github.com/Microsoft/TypeScript/issues/12215#issuecomment-311923766
type Omit<T, K extends keyof T> = Pick<
  T,
  ({ [P in keyof T]: P } &
    { [P in K]: never } & { [x: string]: never; [x: number]: never })[keyof T]
>

export interface IConnect<Config extends IConfiguration> {
  overmind: {
    state: Overmind<Config>['state']
    actions: Overmind<Config>['actions']
    effects: Overmind<Config>['effects']
    addMutationListener: Overmind<Config>['addMutationListener']
    reaction: Overmind<Config>['reaction']
    suspend: <T>(cb: () => T) => T
  }
}

function throwMissingContextError() {
  throw new Error(
    'The Overmind hook could not find an Overmind instance on the context of React. Please make sure you use the Provider component at the top of your application and expose the Overmind instance there. Please read more in the React guide on the website'
  )
}

const context = react.createContext<Overmind<IConfiguration>>({} as Overmind<
  IConfiguration
>)
let nextComponentId = 0

export const Provider: React.ProviderExoticComponent<
  React.ProviderProps<Overmind<IConfiguration> | OvermindMock<IConfiguration>>
> = context.Provider

const useForceRerender = () => {
  const [flushId, setState] = react.useState(() => -1)
  // We use memo here, instead of ref, to support fast-refresh
  const mountedRef = react.useMemo(() => ({ current: true}), [])

  react.useEffect(
    () => () => {
      mountedRef.current = false
    },
    []
  )

  const forceRerender = (_, __, flushId): void => {
    if (mountedRef.current) {
      setState(flushId)
    }
  }
  return {
    forceRerender,
    flushId,
  }
}

let currentComponentInstanceId = 0
const {
  ReactCurrentOwner,
} = (react as any).__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
const useCurrentComponent = () => {
  return ReactCurrentOwner &&
    ReactCurrentOwner.current &&
    ReactCurrentOwner.current.elementType
    ? ReactCurrentOwner.current.elementType
    : {}
}

const useState =  <Config extends IConfiguration>(): Overmind<Config>['state'] => {
  const overmind = react.useContext(context) as Overmind<Config>

  if (!(overmind as any).mode) {
    throwMissingContextError()
  }

  if (isNode || (overmind as any).mode.mode === MODE_SSR) {
    return overmind.state
  }

  const tree = react.useMemo<any>(() =>
    (overmind as any).proxyStateTree.getTrackStateTree(), []
  )

  if (IS_PRODUCTION) {
    const { forceRerender } = useForceRerender()

    react.useEffect(
      () => () => {
        ;(overmind as any).proxyStateTree.disposeTree(tree)
      },
      []
    )

    react.useLayoutEffect(() => tree.stopTracking())

    tree.track(forceRerender)
  } else {
    const component = useCurrentComponent()
    const name = component.name
    component.__componentId =
      typeof component.__componentId === 'undefined'
        ? nextComponentId++
        : component.__componentId

    const { current: componentInstanceId } = react.useRef<any>(
      currentComponentInstanceId++
    )

    const { flushId, forceRerender } = useForceRerender()

    react.useLayoutEffect(() => {
      overmind.eventHub.emitAsync(EventType.COMPONENT_ADD, {
        componentId: component.__componentId,
        componentInstanceId,
        name,
        paths: Array.from(tree.pathDependencies) as any,
      })

      return () => {
        ;(overmind as any).proxyStateTree.disposeTree(tree)
        overmind.eventHub.emitAsync(EventType.COMPONENT_REMOVE, {
          componentId: component.__componentId,
          componentInstanceId,
          name,
        })
      }
    }, [])

    react.useLayoutEffect(() => {
      tree.stopTracking()
      overmind.eventHub.emitAsync(EventType.COMPONENT_UPDATE, {
        componentId: component.__componentId,
        componentInstanceId,
        name,
        flushId,
        paths: Array.from(tree.pathDependencies) as any,
      })
    })
    tree.track(forceRerender)
  }

  return tree.state
}

const useActions =  <Config extends IConfiguration>(): Overmind<Config>['actions'] => {
  const overmind = react.useContext(context) as Overmind<Config>

  if (!(overmind as any).mode) {
    throwMissingContextError()
  }
  
  return overmind.actions
}

const useEffects =  <Config extends IConfiguration>(): Overmind<Config>['effects'] => {
  const overmind = react.useContext(context) as Overmind<Config>

  if (!(overmind as any).mode) {
    throwMissingContextError()
  }
  
  return overmind.effects
}

const useReaction =  <Config extends IConfiguration>(): Overmind<Config>['reaction'] => {
  const overmind = react.useContext(context) as Overmind<Config>

  if (!(overmind as any).mode) {
    throwMissingContextError()
  }
  
  return overmind.reaction
}

export const createStateHook: <Config extends IConfiguration>() => () => Overmind<Config>['state'] = () => {
  return useState as any
}

export const createActionsHook: <Config extends IConfiguration>() => () => Overmind<Config>['actions'] = ()=> {
  return useActions as any
}

export const createEffectsHook: <Config extends IConfiguration>() => () => Overmind<Config>['effects'] = () => {
  return useEffects as any
}

export const createReactionHook: <Config extends IConfiguration>() => () => Overmind<Config>['reaction'] = () => {
  return useReaction as any
}

export const createHook: <Config extends IConfiguration>() => () => {
  state: Overmind<Config>['state']
  actions: Overmind<Config>['actions']
  effects: Overmind<Config>['effects']
  addMutationListener: (cb: IMutationCallback) => () => void
  reaction: Overmind<Config>['reaction']
} = () => {
  return () => {
    const state = useState()
    const actions = useActions()
    const effects = useEffects()
    const overmind = react.useContext(context)

    return {
      state,
      actions,
      effects,
      reaction: overmind.reaction,
      addMutationListener: overmind.addMutationListener
    } as any
  }
}

export const createConnect: <ThisConfig extends IConfiguration>() => <Props>(component: IReactComponent<
  Props & {
    overmind: {
      state: Overmind<ThisConfig>['state']
      actions: Overmind<ThisConfig>['actions']
      reaction: Overmind<ThisConfig>['reaction']
    }
  }
>) => IReactComponent<
  Omit<
    Props & IConnect<Overmind<ThisConfig>>,
    keyof IConnect<Overmind<ThisConfig>>
  >
>  = () => {
  return (
    component
  )=> {
    let componentInstanceId = 0
    const name = component.name
    const populatedComponent = component as any
    populatedComponent.__componentId =
      typeof populatedComponent.__componentId === 'undefined'
        ? nextComponentId++
        : populatedComponent.__componentId
    const isClassComponent =
      component.prototype && typeof component.prototype.render === 'function'

    if (isClassComponent) {
      const originalRender = component.prototype.render
      component.prototype.render = function() {
        if (this.props.overmind) {
          return this.props.overmind.tree.trackScope(
            () => originalRender.call(this),
            this.props.overmind.onUpdate
          )
        }

        return originalRender.call(this)
      }
    }

    if (IS_PRODUCTION) {
      class HOC extends react.Component {
        tree: any
        overmind: any
        state: {
          overmind: any
        }
        wrappedComponent: any
        static contextType = context
        constructor(props, context) {
          super(props)

          if (!context) {
            throwMissingContextError()
          }
          this.overmind = context
          this.tree = this.overmind.proxyStateTree.getTrackStateTree()
          this.state = {
            overmind: {
              state: this.tree.state,
              effects: this.overmind.effects,
              actions: this.overmind.actions,
              addMutationListener: this.overmind.addMutationListener,
              onUpdate: this.onUpdate,
              tree: this.tree,
              reaction: this.overmind.reaction,
            },
          }
          this.wrappedComponent = (...args) =>
            this.tree.trackScope(
              () => (component as any)(...args),
              this.onUpdate
            )
        }
        componentWillUnmount() {
          this.overmind.proxyStateTree.disposeTree(this.tree)
        }
        onUpdate = () => {
          this.setState({
            overmind: {
              state: this.tree.state,
              effects: this.overmind.effects,
              actions: this.overmind.actions,
              addMutationListener: this.overmind.addMutationListener,
              onUpdate: this.onUpdate,
              tree: this.tree,
              reaction: this.overmind.reaction,
            },
          })
        }
        render() {
          if (isClassComponent) {
            return react.createElement(component, {
              ...this.props,
              overmind: this.state.overmind,
            } as any)
          }

          return react.createElement(this.wrappedComponent, {
            ...this.props,
            overmind: this.state.overmind,
          } as any)
        }
      }

      return HOC as any
    } else {
      class HOC extends react.Component {
        tree: any
        overmind: any
        componentInstanceId = componentInstanceId++
        currentFlushId = 0
        state: {
          overmind: any
        }
        isUpdating: boolean
        wrappedComponent: any
        static contextType = context
        constructor(props, context) {
          super(props)

          if (!context) {
            throwMissingContextError()
          }

          this.overmind = context
          this.tree = this.overmind.proxyStateTree.getTrackStateTree()
          this.state = {
            overmind: {
              state: this.tree.state,
              effects: this.overmind.effects,
              actions: this.overmind.actions,
              addMutationListener: this.overmind.addMutationListener,
              onUpdate: this.onUpdate,
              tree: this.tree,
              reaction: this.overmind.reaction,
            },
          }
          this.wrappedComponent = (...args) =>
            this.tree.trackScope(
              () => (component as any)(...args),
              this.onUpdate
            )
        }
        componentDidMount() {
          this.overmind.eventHub.emitAsync(EventType.COMPONENT_ADD, {
            componentId: populatedComponent.__componentId,
            componentInstanceId: this.componentInstanceId,
            name,
            paths: Array.from(this.tree.pathDependencies) as any,
          })
        }
        componentDidUpdate() {
          if (this.isUpdating) {
            this.overmind.eventHub.emitAsync(EventType.COMPONENT_UPDATE, {
              componentId: populatedComponent.__componentId,
              componentInstanceId: this.componentInstanceId,
              name,
              flushId: this.currentFlushId,
              paths: Array.from(this.tree.pathDependencies as Set<string>),
            })
            this.isUpdating = false
          }
        }
        componentWillUnmount() {
          this.overmind.proxyStateTree.disposeTree(this.tree)
          this.overmind.eventHub.emitAsync(EventType.COMPONENT_REMOVE, {
            componentId: populatedComponent.__componentId,
            componentInstanceId: this.componentInstanceId,
            name,
          })
        }
        onUpdate = (mutatons, paths, flushId) => {
          this.currentFlushId = flushId
          this.isUpdating = true
          this.setState({
            overmind: {
              state: this.tree.state,
              effects: this.overmind.effects,
              actions: this.overmind.actions,
              addMutationListener: this.overmind.addMutationListener,
              onUpdate: this.onUpdate,
              tree: this.tree,
              reaction: this.overmind.reaction,
            },
          })
        }
        render() {
          if (isClassComponent) {
            return react.createElement(component, {
              ...this.props,
              overmind: this.state.overmind,
            } as any)
          }
          return react.createElement(this.wrappedComponent, {
            ...this.props,
            overmind: this.state.overmind,
          } as any)
        }
      }

      Object.defineProperties(HOC, {
        name: {
          value: 'Connect' + (component.displayName || component.name || ''),
        },
      })

      return HOC as any
    }
  }
}
